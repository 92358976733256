/*
It makes the Header, Body, Footer in place!
Body fills the remaining space.
Based on:
https://stackoverflow.com/questions/90178/make-a-div-fill-the-height-of-the-remaining-screen-space
*/
html, body {
    height: 100%;
    margin: 0;
}

body{
    background: #c7db96;
}

#root {
    display: flex;
    flex-flow: column;
    height: 100%;
}

#root .rowBody {
    /*border: 1px dotted grey; /* dotted to be removed*/
}

#root .rowBody.headerBody {
    flex: 0 1 auto;
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}

#root .rowBody.contentBody {
    flex: 1 1 auto;
    /*background: #c7db96 /*#dbffe4*/;/*ghostwhite;*/
}

#root .rowBody.footerBody {
    flex: 0 1 auto;
}
/*------------------------------------*/


/*.MuiTooltip-tooltip {*/
/*    font-family: Arial,sans-serif !important;*/
/*}*/